// Generated here https://v2.docusaurus.io/docs/styling-layout/#styling-your-site-with-infima
export const primary = '#2389c9'
export const dark = '#207bb5'
export const darker = '#1e74ab'
export const darkest = '#18608d'
export const light = '#2a96da'
export const lighter = '#349bdc'
export const lightest = '#52aae1'

// Generated from https://smart-swatch.netlify.app/#2389c9 (Hue Up)
export const colors = {
  50: '#def0ff',
  100: '#b8d7f9',
  200: '#8ec1ee',
  300: '#65ace6',
  400: '#3c9add',
  500: '#2389c9', // manually replaced with this value
  600: '#155f99',
  700: '#093d6f',
  800: '#001f45',
  900: '#00091d'
}
