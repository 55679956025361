import {extendTheme} from '@chakra-ui/react'

import {colors} from 'config/colors'

// Font-family
const defaultFontFamily = 'Inter,-apple-system,sans-serif'

// Radial gradient used for backgrounds
export const blueRadialGradient = `radial-gradient(70% 70% at 50% 100%, ${colors[600]} 0, ${colors[500]} 100%)`
export const grayRadialGradient =
  'radial-gradient(70% 70% at 50% 100%, #e8e8e8 0, #fafafa 100%)'

// Default spacing levels
export const space = {
  xs: {base: 1, md: 2},
  sm: {base: 2, md: 4},
  md: {base: 4, md: 8},
  lg: {base: 8, md: 16},
  xl: {base: 10, sm: 16, md: 20, lg: 32},
  '2xl': {base: 12, md: 64}
}

// Max content width
export const maxWidth = 1200

// Default nav sizes
export const navSize = {base: '57px', md: '73px'}

// Add an extra large Button size
const Button = {
  baseStyle: {
    borderRadius: 'sm'
  },
  sizes: {
    xl: {
      fontSize: '1.5rem',
      padding: space.md
    }
  }
}

// Remove rounding from menus
const Menu = {
  parst: ['list'],
  baseStyle: {
    list: {
      borderRadius: 'sm'
    }
  }
}

/**
 * Conveyal specific Chakra UI theme.
 */
export default extendTheme({
  colors: {
    blue: colors
  },
  components: {
    Button,
    Menu
  },
  fonts: {
    body: defaultFontFamily,
    heading: defaultFontFamily
  },
  styles: {
    global: {
      body: {
        color: 'blue.900'
      },
      p: {
        lineHeight: 1.6
      }
    }
  }
})
