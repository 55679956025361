import {ChakraProvider} from '@chakra-ui/react'
import {NextPage} from 'next'
import Head from 'next/head'
import Script from 'next/script'

import ConveyalTheme from 'config/theme'

type AppProps = {
  Component: NextPage
  pageProps: any
}

const localPath = '/fonts/inter-var-latin.woff2'
const fontURL =
  process.env.NODE_ENV === 'production'
    ? `https://assets.conveyal.com${localPath}`
    : localPath

export default function App({Component, pageProps}: AppProps) {
  return (
    <>
      <Head>
        <link
          rel='preload'
          href={fontURL}
          as='font'
          type='font/woff2'
          crossOrigin='anonymous'
        />

        <link
          rel='shortcut icon'
          href='/favicon-32x32.png'
          type='image/x-icon'
        />

        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta property='og:image' content='/android-chrome-512x512.png' />
        <meta property='twitter:image' content='/android-chrome-512x512.png' />
        <meta name='twitter:image:alt' content='Conveyal Logo' />
        <meta name='twitter:card' content='summary_large_image' />
        <meta property='og:url' content='https://conveyal.com/' />

        <style type='text/css' key='inter-font'>{`
          @font-face {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 100 900;
            font-display: optional;
            src: url(${fontURL}) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
              U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
              U+2212, U+2215, U+FEFF, U+FFFD;
          }
        `}</style>
      </Head>
      <Script
        data-api='/api/external/event'
        data-domain='conveyal.com'
        src='/js/external/script.js'
        strategy='lazyOnload'
      />
      <ChakraProvider theme={ConveyalTheme}>
        <Component {...pageProps} />
      </ChakraProvider>
    </>
  )
}
